<template>
  <div>
    <history-table
      id="device-history-list-table"
      :items="items"
      :fields="fields"
      :is-busy="isBusy"
    />
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { msEpochToDate } from '@core/utils/filter'
import store from '@/store/index'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HistoryTable from '../components/HistoryTable.vue'

export default ({
  name: 'DeviceHistory',
  components: {
    HistoryTable,
  },
  props: {
    asset: {
      type: Object,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const items = ref([])
    const device = ref(props.asset.deviceID)
    const toast = useToast()
    const fields = [
      { key: 'amendment', label: 'Amendment', sortable: true },
      { key: 'field', label: 'Field', sortable: true },
      { key: 'fromValue', label: 'From', sortable: true },
      { key: 'toValue', label: 'To', sortable: true },
      { key: 'createdBy', label: 'User', sortable: true },
      { key: 'timestamp', label: 'Time', sortable: true },
    ]
    const isBusy = ref(false)
    const getDeviceHistory = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/getDeviceHistory', device.value)
        .then(response => {
          const { data } = response
          if (!(Object.keys(data).length === 0 && data.constructor === Object)) {
            items.value = data.map(item => {
              // eslint-disable-next-line no-param-reassign
              item.timestamp = msEpochToDate(item.createdAt)
              return item
            })
          }
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Device history Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    watch(() => props.asset.deviceID, newVal => {
      device.value = newVal
      if (newVal) {
        getDeviceHistory()
      }
    })
    return {
      items,
      fields,
      isBusy,
    }
  },
})
</script>

<template>
  <div>
    <history-table
      id="asset-history-list-table"
      :items="items"
      :fields="fields"
      :is-busy="isBusy"
    />
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { msEpochToDate } from '@core/utils/filter'
import store from '@/store/index'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HistoryTable from '../components/HistoryTable.vue'

export default ({
  name: 'AssetHistory',
  components: {
    HistoryTable,
  },
  props: {
    asset: {
      type: Object,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const items = ref([])
    const assetID = ref(props.asset.assetID)
    const toast = useToast()
    const fields = [
      { key: 'deviceID', label: 'Device', sortable: true },
      { key: 'reasonForChange', label: 'Reason', sortable: true },
      { key: 'amendment', label: 'Amendment', sortable: true },
      { key: 'field', label: 'Field', sortable: true },
      { key: 'fromValue', label: 'From', sortable: true },
      { key: 'toValue', label: 'To', sortable: true },
      { key: 'userName', label: 'User', sortable: true },
      { key: 'timestamp', label: 'Time', sortable: true },
    ]
    const isBusy = ref(false)
    const getAssetHistory = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/getAssetHistory', assetID.value)
        .then(response => {
          const { data } = response
          items.value = data.map(item => {
            // eslint-disable-next-line no-param-reassign
            item.timestamp = msEpochToDate(item.timestamp)
            return item
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Asset history Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    watch(() => props.asset.assetID, newVal => {
      assetID.value = newVal
      if (newVal) {
        getAssetHistory()
      }
    })
    return {
      items,
      fields,
      isBusy,
    }
  },
})
</script>

<template>
  <div
    v-if="asset"
    class="m-1"
  >
    <asset-header-card
      :asset="asset"
      :history="true"
    />
    <b-card no-body>
      <b-tabs content-class="mt-3">
        <!-- This tabs content will always be mounted -->
        <b-tab
          title="Asset"
        >
          <asset-history
            :asset="asset"
          />
        </b-tab>

        <!-- This tabs content will not be mounted until the tab is shown -->
        <!-- and will be un-mounted when hidden -->
        <b-tab
          title="Config"
        >
          <asset-config-history
            :asset="asset"
          />
        </b-tab>
        <b-tab
          title="Device"
        >
          <device-history
            :asset="asset"
          />
        </b-tab>
        <b-tab
          title="Commands"
        >
          <device-command-history
            :asset="asset"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssetHeaderCard from '../components/AssetHeaderCard.vue'
import AssetHistory from './AssetHistory.vue'
import AssetConfigHistory from './AssetConfigHistory.vue'
import DeviceHistory from './DeviceHistory.vue'
import DeviceCommandHistory from './DeviceCommandHistory.vue'
import assetFactoryStore from '../assetsLogStore'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AssetHeaderCard,
    AssetHistory,
    AssetConfigHistory,
    DeviceHistory,
    DeviceCommandHistory,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'
    const toast = useToast()
    const isReady = ref(false)
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const asset = ref({})
    store.dispatch('app-asset-log/fetchThisAssets', router.currentRoute.params.assetID)
      .then(response => {
        const { data } = response
        // eslint-disable-next-line prefer-destructuring
        asset.value = data[0]
        isReady.value = true
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Asset Info',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      asset,
      isReady,
    }
  },

}
</script>

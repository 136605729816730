<template>
  <div>
    <b-table
      :id="tableID"
      responsive="sm"
      show-empty
      :fields="fields"
      :items="items"
      primary-key="id"
      :busy="isBusy"
      sort-by="requestedAt"
      :sort-desc="true"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty="scope">
        <h4>No data found</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4>No data found</h4>
      </template>
    </b-table>
    <div class="custom-search d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        :aria-controls="tableID"
      />
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default ({
  name: 'HistoryTable',
  components: {
    BTable,
    BSpinner,
    BPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
      default: 'history-list-table',
    },
  },
  setup(props) {
    const tableID = ref(props.id)
    watch(() => props.id, newVal => {
      tableID.value = newVal
    })
    const currentPage = ref(1)
    const perPage = ref(13)
    const rows = ref(props.items.length)
    watch(() => props.items, newVal => {
      rows.value = newVal.length
    })
    return {
      currentPage,
      perPage,
      rows,
      tableID,
    }
  },
})
</script>
